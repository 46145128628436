<template>
  <div>
    <div class="btn btn-light-primary">哈哈</div>
    <div class="btn  btn-active-color-primary ml-2">
        <span class="svg-icon">
          <inline-svg src="media/svg/icons/Clothes/Brassiere.svg" />
        </span>
    </div>

    <a class="btn btn-icon btn-active-light-primary btn-sm position-relative ml-2">
      <span class="svg-icon svg-icon-3">
        <inline-svg
          src="media/icons/duotune/general/gen019.svg"
        />
        <span class="bullet bullet-dot bg-success x-h-6px x-w-6px position-absolute translate-middle x-top-0 x-start-50 x-animation-blink"></span>
      </span>
    </a>

    <div class="bg-light-primary rounded-circle btn btn-icon pulse pulse-primary ml-2">
      <span class="svg-icon svg-icon-primary">
        <inline-svg src="media/svg/icons/Clothes/Brassiere.svg" />
      </span>
      <span class="pulse-ring "></span>
    </div>

    <span class="svg-icon svg-icon-primary svg-icon-5x ml-2">
      <!--<inline-svg src="mmedia/icons/duotune/art/art002.svg" />-->
      <inline-svg src="media/icons/duotune/technology/teh001.svg" />
    </span>

    <div class="row">
      <div class="col">
        <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 active mt-2">
          <input type="radio" class="btn-check" name="account_team_size" value="1-1">
          <span class="x-text-bold x-fs-3">111</span>
        </label>
      </div>
      <div class="col">
        <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 mt-2">
          <input type="radio" class="btn-check" name="account_team_size" value="1-1">
          <span class="x-text-bold x-fs-3">222</span>
        </label>
      </div>
      <div class="col">
        <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 mt-2">
          <input type="radio" class="btn-check" name="account_team_size" value="1-1">
          <span class="x-text-bold x-fs-3">333</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'example',
}
</script>

<style scoped>

</style>
